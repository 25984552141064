import React from "react";
import {observer} from "mobx-react-lite";
import "./Header.css"

const Header = observer(() => {

    return (
        <div className="header">
            <img alt={"logo"} src={"https://cdn.scanbuy.com/sl/pepsico_logo_60232705b3.png"} className="header_logo"/>
            <img alt={"brand logo"} src={"https://cdn.scanbuy.com/sl/logo_29aa4668e4.webp"} className="brand_logo"/>
            <div className="header_divider"/>
        </div>
    )
})

export default Header;
