import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {ReactLocation, Router, Route,Outlet} from "react-location";
import Manager from "./manager/Manager";

const manager = new Manager()
const routes: Route[] = [
    {
        path: '/',
        loader: async ({params}) => ({
            data: await manager.initData(),
        }),
        element: () =>
        import("./pages/Home").then((module) => <module.default />),
    },
    {
        path: ':brandName',
        loader: async ({params}) => ({
            data: await manager.initData(params),
        }),
        element: ({params}) =>
            import("./pages/Home").then((module) => <module.default params ={{params}}/>),
    },

]
function App() {
    const reactLocation = new ReactLocation()

    return (
        <div className="App">
            <Router routes={routes} location={reactLocation}>
                <Outlet/>
            </Router>
        </div>
    );
}

export default App;
