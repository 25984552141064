import React from "react";
import {observer} from "mobx-react-lite";
import "./Footer.css"

const Footer = observer(() => {

    return (
        <div>
            <div className="footer_divider"/>
            <div className="links">
                <a href="https://pepsicosmartlabel.com/corporate-terms-of-use" >Terms of Use</a>
                <div style={{display:"inline-block"}}>|</div>
                <a href="https://pepsicosmartlabel.com/corporate-terms-of-use">Privacy Policy</a>
            </div>
        </div>
    )
})

export default Footer;
